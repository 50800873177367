<template>
  <div>
    <PageIntroduce
      :title="'企业信息'"
      :desc="'请根据实际情况，填写或及时更新本平台实际使用方的相关企业信息。如果信息不完整或不准确，将影响平台自动生成的报告以及相关文档的准确性。'"
    />
    <el-card
      v-loading="listLoading"
    >
      <div
        slot="header"
        class="flex-between"
      >
        <div class="logo-wrap">
          <img
            :src="enterpriseInfo.logoUrl"
            style="object-fit: fill;width: 100%;height: 100%"
          >
        </div>
        <div class="flex-end">
          <el-button
            v-if="importPerm"
            size="small"
            class="mr10"
            type="primary"
            icon="el-icon-upload"
            @click="importDialogShow"
          >
            导入
          </el-button>
          <el-button
            v-if="exportPerm"
            size="small"
            type="primary"
            icon="el-icon-download"
            @click="exportEnterprise"
          >
            导出
          </el-button>
          <el-button
            v-if="$hasPermission('backups')"
            style="float: right; top: 10px; "
            type="primary"
            size="small"
            icon="el-icon-edit"
            :loading="backupsLoading"
            @click="backupsData"
          >
            备份数据
          </el-button>
          <el-button
            v-if="$hasPermission('reduction')"
            style="float: right; top: 10px; "
            type="primary"
            size="small"
            icon="el-icon-edit"
            @click="reductionHandle"
          >
            还原数据
          </el-button>
          <el-button
            v-if="updatePerm"
            style="float: right; top: 10px; "
            type="primary"
            size="small"
            icon="el-icon-edit"
            @click="dialogVisible = true"
          >
            修改信息
          </el-button>
        </div>
      </div>

      <div class="enterprise-header mb20 justify-content-end">
        <el-form label-width="120px">
          <el-row :gutter="0">
            <el-col :span="12">
              <el-form-item label="企业名称：">
                <span class="font-weight: bold;">{{ enterpriseInfo.name }} </span>

              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属行业：">
                <span class="font-weight: bold;">{{ enterpriseInfo.industry }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="0">
            <el-col :span="12">
              <el-form-item label="企业简称：">
                <span style="font-weight: bold;">{{ enterpriseInfo.shortName }} </span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="企业规模：">
                {{
                  enterpriseInfo.scale ? (enterpriseInfo.scale > 4 ? enterpriseInfo.scale : scaleOptions[enterpriseInfo.scale - 1].label) : ''
                }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="0">
            <el-col :span="12">
              <el-form-item label="企业网站：">
                <span class="text-bold">{{ enterpriseInfo.website }} </span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="信用代码：">
                {{ enterpriseInfo.code }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="0">
            <el-col :span="12">
              <el-form-item label="联系人：">
                <span class="text-bold">{{ enterpriseInfo.contactName }} </span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="办公地址：">
                {{ enterpriseInfo.address }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="0">
            <el-col :span="12">
              <el-form-item label="联系电话：">
                <span class="text-bold">{{ enterpriseInfo.contactPhone }} </span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="邮编：">
                {{ enterpriseInfo.zipCode }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="0">
            <el-col :span="12">
              <el-form-item label="电子邮箱：">
                <span class="text-bold">{{ enterpriseInfo.contactMail }} </span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

      </div>

      <el-dialog
        title="更新信息"
        :visible.sync="dialogVisible"
        width="800px"
        @close="closeFrom"
      >
        <el-form
          v-if="dialogVisible"
          ref="form"
          v-loading="loading"
          :model="form"
          :rules="rules"
          label-width="90px"
          class="mr10"
        >
          <el-row :gutter="0">
            <el-col :span="12">
              <el-form-item
                label="企业LOGO"
              >
                <img
                  v-show="logoPicture"
                  width="80"
                  title="点击重新选择图片"
                  class="img-fluid"
                  :src="logoPicture"
                  style="cursor: pointer"
                  @click="uploadImgdialogVisible = true"
                >
                <el-button
                  v-show="!logoPicture"
                  size="small"
                  @click="uploadImgdialogVisible = true"
                >
                  上传图片
                </el-button>
                <input
                  id="logoFile"
                  accept="image/*"
                  type="file"
                  style="display: none"
                  @change="fileSelect"
                >
                <!--              <div>Logo尺寸 120*120</div>-->
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="企业名称"
                prop="name"
                :rules="[ { required: true, message: '企业名称不能为空', trigger: 'change' }]"
              >
                <el-input
                  v-model="form.name"
                  v-input-filter
                  disabled
                  maxlength="20"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="0">
            <el-col :span="12">
              <el-form-item
                label="企业简称"
                prop="shortName"
                :rules="[ { required: true, message: '企业简称不能为空', trigger: 'change' }]"
              >
                <el-input
                  v-model="form.shortName"
                  v-input-filter
                  maxlength="8"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="企业网站"
                prop="website"
                :rules="[ { required: true, message: '企业网站不能为空', trigger: 'change' }]"
              >
                <el-input
                  v-model="form.website"
                  v-input-filter
                  maxlength="100"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="0">
            <el-col :span="12">
              <el-form-item
                label="信用代码"
              >
                <el-input
                  v-model="form.code"
                  v-input-filter
                  maxlength="100"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="所属行业"
              >
                <el-input
                  v-model="form.industry"
                  maxlength="100"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="0">
            <el-col :span="12">
              <el-form-item
                label="联系人"
                prop="contactName"
                :rules="[ { required: true, message: '联系人不能为空', trigger: 'change' }]"
              >
                <el-input
                  v-model="form.contactName"
                  v-input-filter
                  maxlength="100"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="联系电话"
                prop="contactPhone"
              >
                <el-input
                  v-model="form.contactPhone"
                  v-input-filter
                  placeholder="请输入手机号码或座机号码"
                  maxlength="100"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="0">
            <el-col :span="12">
              <el-form-item
                label="电子邮箱"
                prop="contactMail"
              >
                <el-input
                  v-model="form.contactMail"
                  v-input-filter
                  type="email"
                  maxlength="100"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="企业规模"
              >
                <el-select
                  v-model="form.scale"
                  placeholder="请选择企业规模"
                >
                  <el-option
                    v-for="(item,index) in scaleOptions"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="0">
            <el-col :span="12">
              <el-form-item
                label="办公地址"
                prop="address"
                :rules="[ { required: true, message: '办公地址不能为空', trigger: 'change' }]"
              >
                <el-input
                  v-model="form.address"
                  v-input-filter
                  maxlength="100"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="邮编"
              >
                <el-input
                  v-model="form.zipCode"
                  v-input-filter
                  maxlength="100"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="closeFrom">
            取 消
          </el-button>
          <el-button
            type="primary"
            :disabled="loading"
            @click="submitForm"
          >
            提 交
          </el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="上传图片"
        :visible.sync="uploadImgdialogVisible"
        width="800px"
        @open="uploadImgDialogShow"
      >
        <el-button
          class="mb20"
          size="small"
          @click="logoFileClick"
        >
          选择图片
        </el-button>
        <div
          v-if="uploadImgdialogVisible"
          style="width:100%;height: 400px"
        >
          <vueCropper
            ref="cropper"
            :img="cropperOption.img"
            :output-size="cropperOption.size"
            :output-type="cropperOption.outputType"
            :can-scale="cropperOption.canScale"
            :auto-crop="cropperOption.autoCrop"
            :auto-crop-width="cropperOption.autoCropWidth"
            :auto-crop-height="cropperOption.autoCropHeight"
            :can-move-box="cropperOption.canMoveBox"
            :can-move="cropperOption.canMove"
            :center-box="cropperOption.centerBox"
            :info="cropperOption.info"
            :fixed-box="cropperOption.fixedBox"
          />
        </div>
        <div
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="uploadImgdialogVisible = false">
            取 消
          </el-button>
          <el-button
            type="primary"
            @click="updateLogo"
          >
            确 定
          </el-button>
        </div>
      </el-dialog>
      <ImportEnterprise
        ref="importEnterprise"
        @handleFilter="getEnterpriseInfo"
      />
      <Reduction
        ref="reductionDialog"
        @handleFilter="getEnterpriseInfo"
      />
    </el-card>
  </div>
</template>

<script>
import { ExportEnterprise, getEnterpriseInfo, updateEnterpriseInfo } from '@/api/enterprise/enterprise'
import {
  error, success, verifyEmali, verifyPhone, enterpriseEvent, getBtnPerms, verifyBtnPerm,
} from '@core/utils/utils'
import PageIntroduce from '@core/components/pageIntroduce/PageIntroduce.vue'
import ImportEnterprise from '@/views/enterprise/enterprise/ImportEnterprise.vue'
import ImportProduct from '@/views/enterprise/product/ImportProduct.vue'
import { downloadByGet } from '@/libs/axios'
import Reduction from './reduction.vue'

export default {
  name: 'EnterpriseList',
  components: {
    ImportProduct,
    PageIntroduce,
    ImportEnterprise,
    Reduction,
  },
  data() {
    return {
      updatePerm: false,
      importPerm: false,
      exportPerm: false,
      backupsLoading: false,
      scaleOptions: [
        {
          label: '1-100',
          value: 1,
        },
        {
          label: '101-1000',
          value: 2,
        },
        {
          label: '1001-10000',
          value: 3,
        },
        {
          label: '5000~10000',
          value: 4,
        },
        {
          label: '10000以上',
          value: 5,
        },
      ],
      scaleObj: {
        1: '1-100',
        2: '101-1000',
        3: '1001-10000',
        4: '>10000',
      },
      loading: false,
      dialogVisible: false,
      enterpriseInfo: {
        adminId: '',
        id: '',
        attachments: '',
        code: '',
        compliance: '',
        contactMail: '',
        contactName: '',
        contactPhone: '',
        industry: '',
        logoUrl: '',
        name: '',
        overView: '',
        scale: 0,
        shortName: '',
        status: 0,
        website: '',
      },
      form: {
        adminId: '',
        id: '',
        attachments: '',
        code: '',
        compliance: '',
        contactMail: '',
        contactName: '',
        contactPhone: '',
        industry: '',
        // logoUrl: '',
        name: '',
        overView: '',
        scale: 0,
        shortName: '',
        status: 0,
        website: '',
        zipCode: '',
        address: '',
      },
      logoPicture: '',
      listLoading: false,
      rules: {
        contactMail: [
          {
            validator: verifyEmali,
            trigger: 'change',
          },
        ],
        contactPhone: [
          {
            required: true,
            message: '手机号码不能为空',
            trigger: 'change',
          },
          {
            validator: verifyPhone,
            trigger: 'change',
          },
        ],
        website: [
          // { validator: verifyURL, trigger: 'change' }
        ],
      },
      uploadImgdialogVisible: false,
      cropperOption: {
        img: '',
        size: 200,
        outputType: 'png',
        outputSize: 1, // 裁剪生成图片质量
        canScale: true, // 图片是否允许滚轮播放
        autoCrop: true, // 是否默认生成截图框 false
        info: true, // 是否展示截图框信息
        infoTrue: false,
        // autoCropWidth: 200, // 生成截图框的宽度
        // autoCropHeight: 200, // 生成截图框的高度
        canMoveBox: true, // 截图框是否可以拖动
        fixedBox: false, // 固定截图框的大小
        canMove: true, // 上传图片是否可拖动
        centerBox: true, // 截图框限制在图片里面
      },
      fileBlob: '',
    }
  },
  created() {
    getBtnPerms(btnPerms => {
      this.updatePerm = verifyBtnPerm(btnPerms, 'enterprise-2')
      this.importPerm = verifyBtnPerm(btnPerms, 'enterprise-3')
      this.exportPerm = verifyBtnPerm(btnPerms, 'enterprise-4')
    })
    this.getEnterpriseInfo()
  },
  methods: {
    exportEnterprise() {
      ExportEnterprise()
    },
    importDialogShow() {
      this.$refs.importEnterprise.dialogVisible = true
    },
    updateLogo() {
      // 获取文件流
      this.$refs.cropper.getCropBlob(data => {
        // do something
        this.fileBlob = data
      })
      // 获取base64
      this.$refs.cropper.getCropData(data => {
        this.logoPicture = data
      })
      this.uploadImgdialogVisible = false
    },
    transBase64FromImage(image) {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(image, 0, 0, image.width, image.height)
      return canvas.toDataURL('image/png')
    },
    uploadImgDialogShow() {
      const image = new Image()
      image.src = `${this.logoPicture}?v=${Math.random()}`
      image.crossOrigin = '*'
      image.onload = function () {
        const base64 = this.transBase64FromImage(image)
        this.cropperOption.img = base64
      }
    },
    logoFileClick() {
      document.getElementById('logoFile').click()
    },
    fileSelect() {
      const uploadDom = document.getElementById('logoFile')
      const fileVal = uploadDom.files
      // this.form.fileName = fileVal[0].name
      const that = this
      const file = fileVal[0]
      //   console.log(file);
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function (e) {
        //   console.log(e);
        that.cropperOption.img = this.result
      }
    },
    getEnterpriseInfo() {
      this.listLoading = true
      getEnterpriseInfo().then(res => {
        this.listLoading = false
        this.enterpriseInfo = { ...res.data.data }
        this.form = { ...res.data.data }
        this.enterpriseInfo.logoUrl = `${window.g.baseUrl + this.enterpriseInfo.logoUrl}?x-token=${localStorage.getItem('accessToken')}`
        this.form.logoUrl = window.g.baseUrl + this.form.logoUrl
        this.logoPicture = this.enterpriseInfo.logoUrl
        enterpriseEvent.$emit('enterpriseInfo', this.enterpriseInfo)
        this.$forceUpdate()
      }).catch(() => {
        this.listLoading = true
      })
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const uploadDom = document.getElementById('logoFile')
          const formData = new FormData()
          if (this.fileBlob) {
            formData.append('file', this.fileBlob)
          }
          for (const i in this.form) {
            formData.append(i, this.form[i])
          }
          this.loading = true
          updateEnterpriseInfo(formData)
            .then(res => {
              this.loading = false
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.dialogVisible = false
                this.getEnterpriseInfo()
              } else {
                error(resData.msg)
              }
            })
        }
      })
    },
    closeFrom() {
      this.dialogVisible = false
      this.form = { ...this.enterpriseInfo }
    },
    backupsData() {
      this.backupsLoading = true
      downloadByGet('/sys/backups').then(() => {
        this.backupsLoading = false
      })
    },
    reductionHandle() {
      this.$refs.reductionDialog.dialogVisible = true
    },
  },
}
</script>
<style lang="scss">
.logo-wrap {
  max-width: 100px;
}

.enterprise-header {
  position: relative;

  .el-avatar {
    background: transparent;
  }
}

.enterprise-btn {
  position: absolute;
  right: 20px;
  top: 10px;
}

.el-form-item__content, .el-form-item__label {
  // font-size: 12px;
}
</style>
